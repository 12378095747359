import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import Logo from '../components/Logo'
import rentCategoryImage from '../assets/jpg/rentCategoryImage.jpg'
import sellCategoryImage from '../assets/jpg/sellCategoryImage.jpg'
import { ReactComponent as FacebookIcon } from '../assets/svg/facebookIcon.svg'



function Explore() {
  // const [listing, setListing] = useState(null)

  const navigateToFacebookPage = () => {
    window.location.href = 'https://www.facebook.com/profile.php?id=100092306595003';
  }



  return (
    <div className='explore'>
      <header>
        <p className='pageHeader'><Logo /></p>
        <h1 className='maintag'>Prona në Shitje dhe me Qira</h1>
      </header>

      <main>
        <Slider />

        <p className='exploreCategoryHeading'></p>
        <div className='exploreCategories'>
          <Link to='/category/rent'>
            <img
              src={rentCategoryImage}
              alt='rent'
              className='exploreCategoryImg'
            />
            <h2 className='exploreCategoryName'>Prona me Qira</h2>
          </Link>
          <Link to='/category/sale'>
            <img
              src={sellCategoryImage}
              alt='sell'
              className='exploreCategoryImg'
            />
            <h2 className='exploreCategoryName'>Prona ne Shitje</h2>
            <p className='bottomSpace'></p>
          </Link>
        </div>
      </main>
      <h3>Mirësevini në platformën tonë për prona në shitje dhe me qira. </h3>
      <p>Jemi vendi ku pronarët dhe blerësit takohen për të shkëmbyer informacione dhe negociuar prona ne shtije dhe me qira.</p>
      <p>Platforma jonë ofron një përvojë të përmirësuar për të gjetur prona në shitje dhe me qira. Për pronarët, ofrojmë një platformë të specializuar për të listuar prona në mënyrë të detajuar.</p>
      <p>Mund të shtoni informacion të hollësishëm për prona ne shitje dhe me qira, duke përfshirë fotografi cilësore dhe përshkrim të plotësuar. Kjo ndihmon të tërheqni vëmendjen e blerësve potencialë dhe të siguroni se prona në shitje do të shfaqet në mënyrë të përshtatshme në rezultatet e kërkimit. Për blerësit platforma jone ofron mundesine per te kerkuar prona ne shitje dhe me qira.</p>
      <p>Mund të përdorni kritere të filtrimit te pronave, si vendndodhja e prones, madhësia e prones dhe karakteristikat e tjera, për të gjetur pronën e ëndrrave tuaja. Përdorimi i mjeteve të avancuara të kërkimit dhe strukturës së rregullt të faqes sonë siguron që rezultatet e kërkimit të pronave te jenë relevante dhe të plotësojnë nevojat tuaja.</p>
      <p>Përmes platformës sonë, ju mund të komunikoni direkt me pronarët qe posedojnë prona. Mund të bisedoni me ta për detajet e pronave, të negocioni prona dhe të organizoni takime për t'i parë pronat në terren.</p>
      <p>Ne lehtësojme procesin e blerjes dhe shitjes së pronave, duke siguruar një mjedis të sigurt dhe të besueshëm për të gjithë përdoruesit tanë. </p>
      <p>Regjistrimi në platformën tonë ju jep mundësinë të krijoni një llogari personalizuar te pronave dhe të ndiqni aktivitetin tuaj në mënyrë të lehtë. Ju mund të ruani listimet e pronave dhe kërkimet tuaja të preferuara dhe të keni qasje të shpejtë në to në çdo kohë ne pronat tuaja. </p>
      <p>Ne jemi të përkushtuar për të ofruar një përvojë të shkëlqyer të përdoruesit dhe për të ndihmuar të gjithë ata që janë të interesuar në blerje te pronave, shitjen e pronave dhe pronave me qira të gjejnë zgjidhjen e duhur. Për të filluar, ju ftojmë të regjistroheni në platformën tonë te pronave dhe të shfletoni pronat në shitje dhe me qira.</p>

      <li className='navbarListItem' onClick={navigateToFacebookPage}>
        <FacebookIcon
          fill={'#000000'}
          width='64px'
          height='64px'
        /><p><strong>Na ndiqni në Facebook</strong></p>
      </li>

      <div className="qendra">
        <Link to='/pergjegjesia' className=''>
          Heqja e përgjegjësisë
        </Link>
        <div className='dis'></div>
      </div>


    </div >
  )
}

export default Explore
