import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as OfferIcon } from '../assets/svg/localOfferIcon.svg'
import { ReactComponent as ExploreIcon } from '../assets/svg/exploreIcon.svg'
import { ReactComponent as PersonOutlineIcon } from '../assets/svg/personOutlineIcon.svg'
import { ReactComponent as SearchIcon } from '../assets/svg/searchIcon.svg'

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true
    }
  }

  return (
    <footer className='navbar'>
      <nav className='navbarNav'>
        <ul className='navbarListItems'>
          <li className='navbarListItem' onClick={() => navigate('/')}>
            <ExploreIcon
              fill={pathMatchRoute('/') ? '#FFFFF0' : '#000000'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Shfleto
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/oferta-per-prona')}>
            <OfferIcon
              fill={pathMatchRoute('/oferta-per-prona') ? '#FFFFF0' : '#000000'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/oferta-per-prona')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Ofertat Premium
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/kerko-prona')}>
            <SearchIcon
              fill={pathMatchRoute('/kerko-prona') ? '#FFFFF0' : '#000000'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/kerko-prona')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Kërko
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/profili')}>
            <PersonOutlineIcon
              fill={pathMatchRoute('/profili') ? '#FFFFF0' : '#000000'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/profili')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Profili
            </p>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Navbar
