import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { collection, getDocs, query, orderBy, limit, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'
import Spinner from './Spinner'

SwiperCore.use([Navigation]);

function Slider() {
  const [loading, setLoading] = useState(true)
  const [listings, setListings] = useState([])

  const navigate = useNavigate()



  useEffect(() => {
    const fetchListings = async () => {
      const listingsRef = collection(db, 'listings')
      const q = query(
        listingsRef,
        where('offer', '==', true), // Filter by offer field with true value
        orderBy('timestamp', 'desc'),
        limit(5)
      )

      const querySnap = await getDocs(q)

      if (!querySnap.empty) { // Check if querySnap has documents
        let listings = []

        querySnap.forEach((doc) => {
          listings.push({
            id: doc.id,
            data: doc.data(),
          })
        })

        setListings(listings)
      }

      setLoading(false)
    }

    fetchListings()
  }, [])

  if (loading) {
    return <Spinner />
  }

  if (!listings || !Array.isArray(listings) || listings.length === 0) {
    return <></>;
  }

  return (
    listings && (
      <>
        <p className='exploreHeading'></p>

        <Swiper slidesPerView={1}
          navigation >
          {listings.map(({ data, id }) => (
            <SwiperSlide
              key={id}
              onClick={() => navigate(`/category/${data.type}/${id}`)}
            >


              <div
                style={{
                  background: `url(${data.imgUrls[0]}) center no-repeat`,
                  backgroundSize: 'cover',
                }}
                className='swiperSlideDiv'
              >
                <p className='swiperSlideText'>{data.name}</p>
                <p className='swiperSlidePrice'>
                  €{data.discountedPrice ?? data.regularPrice}{' '}
                  {data.type === 'rent' && '/ muaji'}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper >
      </>
    )
  )
}

export default Slider
