import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { Autoplay } from 'swiper'
import { getDoc, doc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { db } from '../firebase.config'
import Spinner from '../components/Spinner'
import shareIcon from '../assets/svg/shareIcon.svg'
import { useRef } from 'react'; // Add this import statement






function Listing() {
  const [listing, setListing] = useState(null)
  const [loading, setLoading] = useState(true)
  const [shareLinkCopied, setShareLinkCopied] = useState(false)

  const navigate = useNavigate()
  const params = useParams()
  const auth = getAuth()

  const swiperRef = useRef(null); // Add this useRef hook

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setListing(docSnap.data())
        setLoading(false)
      }
    }

    fetchListing()
  }, [navigate, params.listingId])

  if (loading) {
    return <Spinner />
  }

  if (!listing) {
    return null; // Handle the case when listing is null
  }

  return (
    <main>
      <Helmet>
        <title>{listing.name}</title>
      </Helmet>
      <Swiper autoplay={{ delay: 1000 }}
        navigation  // Add the navigation prop
        pagination={{ clickable: true }}
        slidesPerView={1}
        onSwiper={(swiper) => {
          swiperRef.current = swiper; // Save the swiper instance in the ref
        }}
      >
        {listing.imgUrls.map((url, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                background: `url(${listing.imgUrls[index]}) center no-repeat`,
                backgroundSize: 'cover',
              }}
              className='swiperSlideDiv'
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <button
        className="swiper-button-prev"
        onClick={() => swiperRef.current.slidePrev()} // Access the swiper instance from the ref
      >
        Previous
      </button>
      <button
        className="swiper-button-next"
        onClick={() => swiperRef.current.slideNext()} // Access the swiper instance from the ref
      >
        Next
      </button> */}

      <div
        className='shareIconDiv'
        onClick={() => {
          navigator.clipboard.writeText(window.location.href)
          setShareLinkCopied(true)
          setTimeout(() => {
            setShareLinkCopied(false)
          }, 2000)
        }}
      >
        <img src={shareIcon} alt='' />
      </div>

      {shareLinkCopied && <p className='linkCopied'>Linku u kopjua</p>}

      <div className='listingDetails'>
        <p className='listingName'>
          {listing && listing.name && (
            <>
              {listing.name} - €
              {listing.offer
                ? listing.discountedPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : listing.regularPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </>
          )}
        </p>
        <p className='listingLocation'>{listing.location}</p>
        <p className='listingType'>
          {listing.type === 'rent' ? 'Me qira' : 'Ne shitje'}
        </p>
        {listing.offer && (
          <p className='discountPrice'>
            €{listing.regularPrice - listing.discountedPrice} ulje
          </p>
        )}

        <ul className='listingDetailsList'>
          <li>
            {listing.bedrooms > 1
              ? `${listing.bedrooms} dhoma Gjumi`
              : '1 dhomë Gjumi'}
          </li>
          <li>
            {listing.bathrooms > 1
              ? `${listing.bathrooms} Banjo`
              : '1 Banjo'}
          </li>
          <li>{listing.parking && 'Parking'}</li>
          <li>{listing.furnished && 'Mobiluar'}</li>
          <li>{listing.space} metra kator</li>
        </ul>
        <h4>Qyteti: {listing.city}</h4>
        <h4>Kontakti: {listing.phone}</h4>


        <p className='listingLocationTitle'>Lokacioni</p>

        <div className='leafletContainer'>
          <MapContainer
            style={{ height: '100%', width: '100%' }}
            center={[listing.latitude, listing.longitude]}
            zoom={15}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png'
            />

            <Marker className="markeri"
              position={[listing.latitude, listing.longitude]}
            >
              <Popup>{listing.location}</Popup>
            </Marker>
          </MapContainer>
        </div>

        {/* {auth.currentUser?.uid !== listing.userRef && (
          <Link
            to={`/contact/${listing.userRef}?listingName=${listing.name}`}
            className='primaryButton'
          >
            Kontakto Pronarin
          </Link>
        )} */}
      </div>

      <Helmet>
        <title>{listing.name}</title>
        <meta property="og:title" content={listing.name} />
        <meta property="og:description" content={listing.location} />
        <meta property="og:image" content={listing.imgUrls[0]} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="shpiza.com" />
      </Helmet>
    </main>
  )
}

export default Listing

// https://stackoverflow.com/questions/67552020/how-to-fix-error-failed-to-compile-node-modules-react-leaflet-core-esm-pat
