import logoja from '../assets/jpg/logo.png'

function Logo() {
    
    return (

      <img
          src={logoja}
          alt="logoja"
          className=''
        />  
    )

    

}

export default Logo