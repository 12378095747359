import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../components/Spinner'
import Select from 'react-select';

function CreateListing() {
  // eslint-disable-next-line
  const [geolocationEnabled, setGeolocationEnabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    type: 'rent',
    name: '',
    bedrooms: 1,
    bathrooms: 1,
    parking: false,
    furnished: false,
    space: 1,
    phone: '',
    address: '',
    offer: false,
    regularPrice: 0,
    discountedPrice: 0,
    images: [],
    latitude: 0,
    longitude: 0,
    city: '',
  })

  const {
    type,
    name,
    bedrooms,
    bathrooms,
    parking,
    furnished,
    space,
    phone,
    address,
    offer,
    regularPrice,
    discountedPrice,
    images,
    latitude,
    longitude,
    city,
  } = formData

  const [category, setCategory] = useState(null);
  const cityList = [
    { value: 'prishtine', label: 'Prishtinë' },
    { value: 'prizren', label: 'Prizren' },
    { value: 'peje', label: 'Pejë' },
    { value: 'ferizaj', label: 'Ferizaj' },
    { value: 'mitrovice', label: 'Mitrovicë' },
    { value: 'gjilan', label: 'Gjilan' },
    { value: 'gjakove', label: 'Gjakovë' },
  ];

  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)



  const handleCityChange = (option) => {
    setCategory(option?.value);
    setFormData((prevState) => ({
      ...prevState,
      city: option?.value, // Update the city value in the form data
    }));
  };


  useEffect(() => {



    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/sign-in')
        }
      })
    }


    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    if (discountedPrice >= regularPrice) {
      setLoading(false)
      toast.error('Çmimi me ulje duhet te jete me i vogel se cmimi i rregullt')
      return
    }

    if (images.length > 6) {
      setLoading(false)
      toast.error('Maksimumi 6 foto')
      return
    }


    // let geolocation = {}
    // let location

    // if (geolocationEnabled) {
    //   const response = await fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
    //   )

    //   const data = await response.json()

    //   geolocation.lat = data.results[0]?.geometry.location.lat ?? 0
    //   geolocation.lng = data.results[0]?.geometry.location.lng ?? 0

    //   location =
    //     data.status === 'ZERO_RESULTS'
    //       ? undefined
    //       : data.results[0]?.formatted_address

    //   if (location === undefined || location.includes('undefined')) {
    //     setLoading(false)
    //     toast.error('Please enter a correct address')
    //     return
    //   }
    // } else {
    //   geolocation.lat = latitude
    //   geolocation.lng = longitude
    // }




    // Store image in firebase
    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

        const storageRef = ref(storage, 'images/' + fileName)

        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          (error) => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch(() => {
      setLoading(false)
      toast.error('Fotot nuk u ngarkuan')
      return
    })

    const formDataCopy = {
      ...formData,
      space: Number(formData.space), // Convert space to a number
      imgUrls,
      timestamp: serverTimestamp(),
    }
    // console.log(`tipi`,typeof formDataCopy.space, formDataCopy.space);
    formDataCopy.location = address
    delete formDataCopy.images
    delete formDataCopy.address
    !formDataCopy.offer && delete formDataCopy.discountedPrice

    const docRef = await addDoc(collection(db, 'listings'), formDataCopy)
    setLoading(false)
    toast.success('Listimi u ruajt')
    navigate(`/category/${formDataCopy.type}/${docRef.id}`)
  }
  console.log(typeof formData.space)


  const onMutate = (e) => {
    let boolean = null;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }



    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files ?? boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
      }));
    }
  };


  if (loading) {
    return <Spinner />
  }

  return (
    <div className='profile'>
      <header>
        <p className='pageHeader'>Listo pronën</p>
      </header>

      <main>
        <form onSubmit={onSubmit}>
          <label className='formLabel'>Shitje / Me qira</label>
          <div className='formButtons'>
            <button
              type='button'
              className={type === 'sale' ? 'formButtonActive' : 'formButton'}
              id='type'
              value='sale'
              onClick={onMutate}
            >
              Shitje
            </button>
            <button
              type='button'
              className={type === 'rent' ? 'formButtonActive' : 'formButton'}
              id='type'
              value='rent'
              onClick={onMutate}
            >
              Me Qira
            </button>
          </div>

          <label className='formLabel'>Emërtimi i pronës</label>
          <input
            className='formInputName'
            type='text'
            id='name'
            value={name}
            onChange={onMutate}
            maxLength='32'
            minLength='10'
            required
          />

          <div className='formRooms flex'>
            <div>
              <label className='formLabel'>Dhoma</label>
              <input
                className='formInputSmall'
                type='number'
                id='bedrooms'
                value={bedrooms}
                onChange={onMutate}
                min='1'
                max='50'
                required
              />
            </div>
            <div>
              <label className='formLabel'>Banjo</label>
              <input
                className='formInputSmall'
                type='number'
                id='bathrooms'
                value={bathrooms}
                onChange={onMutate}
                min='1'
                max='50'
                required
              />
            </div>
          </div>

          <label className='formLabel'>Vend Parkimi</label>
          <div className='formButtons'>
            <button
              className={parking ? 'formButtonActive' : 'formButton'}
              type='button'
              id='parking'
              value={true}
              onClick={onMutate}
              min='1'
              max='50'
            >
              Po
            </button>
            <button
              className={
                !parking && parking !== null ? 'formButtonActive' : 'formButton'
              }
              type='button'
              id='parking'
              value={false}
              onClick={onMutate}
            >
              Jo
            </button>
          </div>

          <label className='formLabel'>E mobiluar</label>
          <div className='formButtons'>
            <button
              className={furnished ? 'formButtonActive' : 'formButton'}
              type='button'
              id='furnished'
              value={true}
              onClick={onMutate}
            >
              Po
            </button>
            <button
              className={
                !furnished && furnished !== null
                  ? 'formButtonActive'
                  : 'formButton'
              }
              type='button'
              id='furnished'
              value={false}
              onClick={onMutate}
            >
              Jo
            </button>
          </div>

          <label className='formLabel'>Sipërfaqja në metër katror</label>
          <input
            className='formInputName'
            type='number'
            id='space'
            value={formData.space}
            onChange={onMutate}
            maxLength='6'
            minLength='2'
            min='20'
            required
          />

          <label className='formLabel'>Latitude</label>
          <input
            className='formInputName'
            type='text'
            id='latitude'
            value={latitude}
            onChange={onMutate}
            maxLength='6'
            minLength='2'
            required
          />

          <label className='formLabel'>Longitude</label>
          <input
            className='formInputName'
            type='text'
            id='longitude'
            value={longitude}
            onChange={onMutate}
            maxLength='6'
            minLength='2'
            required
          />

          <label className='formLabel'>Telefoni</label>
          <input
            className='formInputName'
            type='text'
            id='phone'
            value={phone}
            onChange={onMutate}
            maxLength='32'
            minLength='9'
            required
          />

          <label className='formLabel'>Adresa</label>
          <textarea
            className='formInputAddress'
            type='text'
            id='address'
            value={address}
            onChange={onMutate}
            required
          />

          <label className='formLabel'>Çmimi i rregult</label>
          <div className='formPriceDiv'>
            <input
              className='formInputSmall'
              type='number'
              id='regularPrice'
              value={regularPrice}
              onChange={onMutate}
              min='200'
              max='9000000'
              required
            />
            {type === 'rent' && <p className='formPriceText'>€ / Muaji</p>}
          </div>

          {offer && (
            <>
              <label className='formLabel'>Çmimi me ulje</label>
              <input
                className='formInputSmall'
                type='number'
                id='discountedPrice'
                value={discountedPrice}
                onChange={onMutate}
                min='50'
                max='750000000'
                required={offer}
              />
            </>
          )}

          {/* <label className='formLabel'>Qyteti</label>
          <input
            className='formInputName'
            type='text'
            id='city'
            value={city}
            onChange={onMutate}
            maxLength='16'
            minLength='3'
            required
          /> */}

          <h3>Zgjedh Qytetin</h3>
          <Select
            className='formSelect'
            options={cityList}
            onChange={handleCityChange}
            id='city'
            required
          />

          <label className='formLabel'>Fotot</label>
          <p className='imagesInfo'>
            Foto e pare do te vendoset ne cover (max 6).
          </p>
          <input
            className='formInputFile'
            type='file'
            id='images'
            onChange={onMutate}
            max='6'
            accept='.jpg,.png,.jpeg'
            multiple
            required
          />
          <button type='submit' className='primaryButton createListingButton'>
            Listo
          </button>
        </form>
      </main>
    </div>
  )
}

export default CreateListing
