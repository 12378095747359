import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDi3B_wqk_BSUpQzrdo1o_0V0Y5SJgZg94',
  authDomain: 'elite-4971f.firebaseapp.com',
  projectId: 'elite-4971f',
  storageBucket: 'elite-4971f.appspot.com',
  messagingSenderId: '960145782563',
  appId: '1:960145782563:web:71cee7b464992479703e96',
}

// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()
