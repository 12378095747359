import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import ListingItem from '../components/ListingItem';
import Select from 'react-select';
import { startAfter, limit } from 'firebase/firestore';


function Search() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const categories = [
    { value: 'prishtine', label: 'Prishtinë' },
    { value: 'prizren', label: 'Prizren' },
    { value: 'peje', label: 'Pejë' },
    { value: 'ferizaj', label: 'Ferizaj' },
    { value: 'mitrovice', label: 'Mitrovicë' },
    { value: 'gjilan', label: 'Gjilan' },
    { value: 'gjakove', label: 'Gjakovë' },
  ];


  const spaceOptions = [
    { value: { min: 20, max: 100 }, label: '20 deri 100 metra katror' },
    { value: { min: 101, max: 150 }, label: '101 deri 150 metra katror' },
    { value: { min: 151, max: 200 }, label: '151 deri 200 metra katror' },
    { value: { min: 201, max: 300 }, label: '201 deri 300 metra katror' },
    { value: { min: 301, max: 1000 }, label: '301 deri 1000 metra katror' },
  ];

  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);;
  const [selectedSpace, setSelectedSpace] = useState(null);
  const params = useParams();


  const fetchListings = async () => {
    setLoading(true);

    try {
      const listingsRef = collection(db, 'listings');
      let q = query(listingsRef);

      if (selectedType) {
        q = query(q, where('type', '==', selectedType));
      }


      if (category) {
        q = query(q, where('city', '==', category));
      }

      if (selectedSpace) {
        const { min, max } = selectedSpace;
        console.log('Selected space X (min, max):', min, max);
        q = query(q, where('space', '>=', min), where('space', '<=', max));
      }

      const querySnap = await getDocs(q);

      const fetchedListings = [];

      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      console.log('Fetched listings:', fetchedListings);
      setListings(fetchedListings);
      setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1] || null);
    } catch (error) {
      console.error('Error fetching listings:', error);
      toast.error('Listimi nuk mund të bëhet');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      return;
    }


    fetchListings();


  }, [selectedType, category, selectedSpace, initialLoad]);

  useEffect(() => {
    // Create a flag to check if both selectedSpace and category have values
    const isSelectionComplete = selectedType && category;

    // Call fetchListings only when both selections are complete
    if (isSelectionComplete) {
      fetchListings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, category, selectedSpace]);


  const handleSpaceChange = (option) => {
    if (option && option.value && option.value.min && option.value.max) {
      const { min, max } = option.value;
      setSelectedSpace({ min, max });
      console.log('Selected space (min, max):', min, max);
      // fetchListings(); // Remove this line
    } else {
      // Handle the case when the selected option doesn't have the expected structure
      console.error('Invalid space option:', option);
    }
  };


  // Reset category, rooms, and roomLabel to initial values when the city is changed
  const handleCityChange = (option) => {
    setCategory(option?.value);
    fetchListings(); // Trigger fetching of listings again
  };


  // Reset type to initial value when the user clears the type selection
  const handleTypeClear = () => {
    setType(null);
    fetchListings();
  };

  // Handle type change
  const handleTypeChange = (option) => {
    setSelectedType(option.value);
    setCategory(null); // Reset the category to prevent mixed results
    setSelectedSpace(null); // Reset the selected space to prevent mixed results
  };


  // Pagination / Load More
  const onFetchMoreListings = async () => {
    setLoading(true);

    try {
      const listingsRef = collection(db, 'listings');
      let q = query(listingsRef);

      if (selectedType) {
        q = query(q, where('type', '==', selectedType));
      }

      if (category) {
        q = query(q, where('city', '==', category));
      }

      if (selectedSpace) {
        const { min, max } = selectedSpace;
        console.log('Selected space X (min, max):', min, max);
        q = query(q, where('space', '>=', min), where('space', '<=', max));
      }

      if (lastFetchedListing) {
        q = query(q, startAfter(lastFetchedListing)); // Use the document ID for pagination
      }

      const querySnap = await getDocs(q);

      const fetchedListings = [];

      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setListings((prevState) => [...prevState, ...fetchedListings]);

      setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1]);
    } catch (error) {
      toast.error('Listimi nuk mund të bëhet');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='category'>
      <header>
        <h2> Kërkimi </h2>
      </header>

      <form>
        <h3>Zgjedh Kategorinë</h3>
        <Select
          className='formSelect'
          options={[
            { value: 'rent', label: 'Me Qira' },
            { value: 'sale', label: 'Ne Shitje' },
          ]}
          value={selectedType ? { value: selectedType, label: selectedType === 'rent' ? 'Me Qira' : 'Ne Shitje' } : null}
          onChange={handleTypeChange}
          onMenuClose={handleTypeClear}
        />

        {/* Display the selected rent value */}
        {type === 'rent' && (
          <p>Selected rent: Me Qira</p>
        )}

        <h3>Zgjedh Qytetin</h3>
        <Select
          className='formSelect'
          options={categories}
          onChange={handleCityChange}
        />


        <h3>Zgjedh Hapësirën (m2)</h3>
        <Select
          className='formSelect'
          options={spaceOptions}
          onChange={handleSpaceChange}
        />

      </form>

      {loading ? (
        <Spinner />
      ) : listings && listings.length > 0 ? (
        <>
          <main>
            <ul className='categoryListings'>
              {listings
                .map((listing) => (
                  <ListingItem
                    listing={listing.data}
                    id={listing.id}
                    key={listing.id}
                  />
                ))}
            </ul>
          </main>

          <br />
          <br />
          {lastFetchedListing && (
            <p className='loadMore' onClick={onFetchMoreListings}>
              Shfleto më shumë
            </p>
          )}
        </>
      ) : (
        <p>Ska listime për {params.categoryName}</p>
      )}
    </div>
  );
}

export default Search