import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar'
import PrivateRoute from './components/PrivateRoute'
import Explore from './pages/Explore'
import Offers from './pages/Offers'
import Category from './pages/Category'
import Profile from './pages/Profile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import CreateListing from './pages/CreateListing'
import EditListing from './pages/EditListing'
import Listing from './pages/Listing'
import Contact from './pages/Contact'
import Search from './pages/Search'
import Pergjegjesia from './pages/Pergjegjesia'


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Explore />} />
          <Route path='/oferta-per-prona' element={<Offers />} />
          <Route path='/kerko-prona' element={<Search />} />
          <Route path='/category/:categoryName' element={<Category />} />
          <Route path='/profili' element={<PrivateRoute />}>
            <Route path='/profili' element={<Profile />} />
          </Route>
          <Route path='/kyqu' element={<SignIn />} />
          <Route path='/regjistrohu' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/listo-prona' element={<CreateListing />} />
          <Route path='/edit-listing/:listingId' element={<EditListing />} />
          <Route
            path='/category/:categoryName/:listingId'
            element={<Listing />}
          />
          {/* <Route path='/contact/:landlordId' element={<Contact />} /> */}
          <Route path='/pergjegjesia' element={<Pergjegjesia />} />
        </Routes>
        <Navbar />
      </Router>

      <ToastContainer />
    </>
  )
}

export default App
